const logEvent = (evtName, message) => {
  console.log(message)
  const evt = new CustomEvent(evtName, {
    detail: {
      message
    }
  })
  document.getRootNode().dispatchEvent(evt)
}

const updateText = {
  span: {
    en: 'A new version of this app is available.',
    de: 'Eine neue Version dieser App ist verfügbar.'
  },
  btn: {
    en: 'Update',
    de: 'Aktualisieren'
  },
  update: {
    en: 'Updating the app, this might take some few minutes.',
    de: 'App wird aktualisiert. Dies kann einige Minuten dauern.'
  }
}

const isPWA = () => {
  return window.matchMedia('(display-mode: standalone)').matches ||
  window.matchMedia('(display-mode: fullscreen)').matches ||
  window.navigator.standalone === true ||
  window.location.search.indexOf('utm_source=homescreen') >= 0
}

// workaround global state for the force refresh callback used by showUI function
let cancelForceRefreshTimer = null
/**
 * informs user that a new service worker is waiting to be activated
 * sets the cancelForceRefreshTimer which you can call anywhere to cancel the failsafe auto refresh
 * @param {ServiceWorker} sw
 * @param {number} timeout used by the cancelForceRefreshTimer as the duration before executing
 * @returns {void} instead this function sets the global cancelForceRefreshTimer callback
 */
function showUI (sw, timeout = 5000) {
  /**
   * sends a message to the sw to force activate the sw
   *  */
  const skipWaiting = () => {
    logEvent('serviceworker:waiting-skip', 'force activate new service worker')
    sw.postMessage({
      action: 'skipWaiting'
    })
  }

  /**
   * a failsafe to not make the user stuck during sw activation
   * @param {callback} action run before refreshing
   * @returns {callback} failsafe cancellation
   */
  const refreshFailsafe = (action = () => ({})) => {
    const cancelId = setTimeout(() => {
      logEvent('serviceworker:failsafe-reload', 'failsafe will force activation and reload page')
      action()
      sw.state === 'installed' && skipWaiting() // force skipwaiting
      window.location.reload()
    }, timeout)

    return () => {
      logEvent('serviceworker:failsafe-cancel', 'cancelling failsafe. sw must have activated successfully')
      clearTimeout(cancelId)
    }
  }

  const isInExcludedPath = () => {
    const excludedPaths = [
      '/login',
      '/forgot-password',
      '/registration',
      '/request-magic-link'
    ]
    return excludedPaths.find(p => window.location.pathname.match(p) !== null)
  }

  // Automatically refresh if not on PWA
  if (!isPWA() && !isInExcludedPath()) {
    skipWaiting()
    cancelForceRefreshTimer = refreshFailsafe()
  } else {
    const isInExcludedPath = () => {
      const excludedPaths = [
        '/login',
        '/forgot-password',
        '/registration',
        '/request-magic-link'
      ]
      return excludedPaths.find(p => window.location.pathname.match(p) !== null)
    }

    // we do not want to show the update screen in some pages for ux reasons
    if (!isInExcludedPath()) {
      /**
       * this shows the update ui
       * @param {callback} action to execute on click
       */
      const enableUI = (action) => {
        const updateAvailableContainer = document.getElementById('pwa-update-available')
        const text = document.getElementById('pwa-update-text')
        const reload = document.getElementById('reload')
        const pwaUpdateContainer = document.getElementById('pwa-update-container')
        updateAvailableContainer.classList.remove('hide')
        text.textContent = updateText.span[window.Lang.locale() ?? 'en']
        reload.textContent = updateText.btn[window.Lang.locale() ?? 'en']
        logEvent('serviceworker:update-show', `sw state: ${sw.state}`)
        reload.addEventListener('click', function () {
          const newText = updateText.update[window.Lang.locale() ?? 'en']
          pwaUpdateContainer.innerHTML = `<div class="pwa-update-text-container"><span id="pwa-update-text">${newText}</span></div>`
          logEvent('serviceworker:update-clicked', 'update button has been clicked')
          action()
        })
      }

      enableUI(() => {
        skipWaiting()
        cancelForceRefreshTimer = refreshFailsafe()
      })
    }
  }
}

// the code for reqistering the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((reg) => {
      logEvent('serviceworker:register-success', 'service worker: successfully registered!')

      // SW wont automatically install unless all instances are closed
      if (reg.waiting) {
        logEvent('serviceworker:waiting', 'service worker: waiting for all open instances to close before activating.')
        showUI(reg.waiting)
      }

      // If new update found for service worker
      reg.onupdatefound = () => {
        const newWorker = reg.installing // New service worker TO BE installed.

        newWorker.addEventListener('statechange', (evt) => {
          switch (evt.target.state) {
            // "installing" - the install event has fired, but not yet complete
            case 'installing':
              logEvent('serviceworker:installing', 'service worker: update found! installing new worker... 🔄')
              break
            // "installed"  - install complete
            case 'installed':
              logEvent('serviceworker:installed', 'service worker: installed new worker successfully and ready to activate! 🚀')
              showUI(newWorker)
              break
            // "activating" - the activate event has fired, but not yet complete
            case 'activating':
              logEvent('serviceworker:activating', 'service worker: activating! 🚀')
              break
            // "activated"  - fully active
            case 'activated':
              logEvent('serviceworker:activated', 'service worker: activated! 🚀')
              break
            // "redundant"  - discarded. Either failed install, or it's been
            //                replaced by a newer version
            case 'redundant':
              logEvent('serviceworker:redundant', 'service worker: discarded! 🚀')
              break
            default:
              logEvent('serviceworker:default', 'service worker: default case! 🚀')
              break
          }
        })
      }

      // called when service worker activated has changed
      //  note: if this is looping please uncheck application > service worker > update on reload
      navigator.serviceWorker
        .addEventListener('controllerchange', function (evt) {
          // just to keep a reference to the service worker state. usually its 'waiting' or 'activated'
          const state = evt.currentTarget.controller.state
          logEvent('serviceworker:controller-change', `service worker changed w/ state:${state}. Will reload the application`)
          cancelForceRefreshTimer && cancelForceRefreshTimer() // cancel failsafe and reload
          cancelForceRefreshTimer = null
          window.location.reload()
        })

      // Add update checker on visibility changed
      document.addEventListener('visibilitychange', function () {
        logEvent('serviceworker:visibility-changed', 'service worker: visiblity change. Will check for updates.')
        reg.update()
      }, false)
    })
    .catch((err) => {
      logEvent('serviceworker:register-failed', err)
    })
}
